import React , { useState , useEffect } from 'react';

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import { Dropdown } from 'react-bootstrap';

import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo_dark.png';
import logodark2 from '../../assets/images/logo/logo_dark2.png';

import DarkMode from './DarkMode';

import { useWeb3Modal, useWeb3ModalState, useWeb3ModalTheme } from '@web3modal/wagmi/react';

// import Button from '../button';



const Header = () => {

    const { open } = useWeb3Modal()
    const { themeMode, themeVariables, setThemeMode, setThemeVariables } = useWeb3ModalTheme()

    setThemeMode('dark')
    setThemeVariables({
        '--w3m-accent': 'linear-gradient(156.67deg, rgba(112, 112, 114, 0.4) 0.07%, rgba(100, 97, 113, 0.148) 99.07%)',
        "--w3m-border-radius-master" : '2px'
    })

    // const [scroll, setScroll] = useState(false);
    //     useEffect(() => {
    //         window.addEventListener("scroll", () => {
    //             setScroll(window.scrollY > 90);
    //         });
    //         return () => {
    //             setScroll({});
    //         }
    // }, []);

    const [menuActive, setMenuActive] = useState(false);
    // const handleMenuActive = () => {
    //     setMenuActive(!menuActive);
    // };
    
    const [activeIndex, setActiveIndex] = useState(false);
    // const handleDropdown = index => {
    //     setActiveIndex(index); 
    // };

    return (
        // ${scroll ? 'is-fixed' : ''}
        <header className={`header is-fixed`}>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">                              
                            <div id="site-header-inner">                                 
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <a href="/" rel="home" className="main-logo">
                                            <img id="logo_header" className='logo-dark'  src={logodark2} alt="Air3" />
                                            <img id="logo_header" className='logo-light' src={logo} alt="Air3" />
                                        </a>
                                    </div>
                                </div>
                                
                               <div className="header-center">
                                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map(( data, idx) => (
                                                <li key={idx} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} >
                                                    <Link to={data.links}>{data.name}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </nav>
                               </div>

                               <div className="header-right">
                                    <div className="align-middle m-3">
                                        <a className='mr-4' href="https://twitter.com/air3app" target="_blank"><img src="./img/social/x-512.png"  width="17px" height="17px"/></a>
                                        <a className='mr-4' href="https://t.me/+oedeIMakcwIyNWJl" target="_blank"><img src="./img/social/telegram-512.png"  width="18px" height="18px"/></a>
                                        <a className='mr-4' href="https://github.com/air3app/air3labs" target="_blank"><img src="./img/social/github-512.png" width="18px" height="18px"/></a>
                                    </div>
                                    <div className="align-middle m-2">
                                        <w3m-button balance='hide' label='Connect Wallet' />
                                    </div>
                                </div> 

                                <div className={`mobile-button ${menuActive ? 'active' : ''}`}>
                                    {
                                        menus.map(( data, idx) => (
                                            <li key={idx} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} >
                                                <Link to={data.links}>{data.name}</Link>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
       
    );
}

export default Header;