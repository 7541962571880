import { React , useEffect, useState, useCallback } from 'react'
// import '../src/assets/binasea.css'
// import '../src/assets/font-awesome.css'

import AOS from 'aos'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'

import Home01 from "./pages/Home01"
import { MintPage } from "./pages/mintpage"
import Faqs from "./pages/Faqs"
import Factory from "./pages/Factory"
import Page404 from './pages/404'

// import '@rainbow-me/rainbowkit/styles.css'
// import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit'
import { configureChains, createConfig, WagmiConfig, useNetwork, useAccount, useConnect, useDisconnect } from 'wagmi'
import { goerli, base, zkSync, polygon, arbitrum, mainnet, linea, polygonZkEvm } from 'wagmi/chains'
import { scroll } from './customChain/scroll.ts'
import { opBNB } from './customChain/opBNB.ts'
import { Lightlink } from './customChain/lightlink.ts'
import { tenet } from './customChain/tenet.ts'
import { manta } from './customChain/manta.ts'
import { zeta } from './customChain/zeta.ts'
import { taiko } from './customChain/taiko.ts'
import { mantle } from './customChain/mantle.ts'
import { frame } from './customChain/frame-testnet.ts'
import { x1testnet } from './customChain/x1-testnet.ts'
import { berachaintestnet } from './customChain/berachain-testnet.ts'
import { optimism } from './customChain/optimism.ts'

import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from '@wagmi/core/providers/public'
import { getNetwork } from '@wagmi/core'
import { watchNetwork } from '@wagmi/core'
import { getPublicClient } from '@wagmi/core'

import { dataChain } from './assets/real-data/data-chains'

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import GetChain  from './pages/GetChain'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGCZNPSb68ejB8MH0suOAlPR6vS4P3tYs",
  authDomain: "air3-app.firebaseapp.com",
  projectId: "air3-app",
  storageBucket: "air3-app.appspot.com",
  messagingSenderId: "379325203266",
  appId: "1:379325203266:web:e11e1f62c982556a6b7edb",
  measurementId: "G-G697MEDGFJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {

    // 1. Get projectId
    const projectId = 'a2bf76b0d033cd8daa0c63ae96998e4c'

    // 2. Create wagmiConfig

    const { chains, publicClient } = configureChains(
        [ linea, opBNB, scroll, base,
             manta, zeta, taiko, mantle, frame,
            polygonZkEvm, optimism, x1testnet, berachaintestnet ],
        [walletConnectProvider({ projectId }), publicProvider()]
      )

    const metadata = {
        name: 'Air3',
        description: 'Air3',
        url: 'https://web3modal.com',
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    }

    const wagmiConfig = createConfig({
        autoConnect: true,
        connectors: [
            new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
            new EIP6963Connector({ chains }),
            new InjectedConnector({ chains, options: { shimDisconnect: true } }),
            new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
        ],
        publicClient
    })
  

    // 3. Create modal
    createWeb3Modal({ wagmiConfig, projectId, chains,
        chainImages: {
        1:		'./img/chains/mainnet.png', // mainnet
        5:		'./img/chains/goerli-testnet.png', // goerli
        137:	'./img/chains/polygon.png', // polygon
        534352: './img/chains/scroll.png', // scroll
        1559:	'./img/chains/tenet.png',// tenet
        59144: 	'./img/chains/linea.png', // linea
        204: 	'./img/chains/opbnb.png',// opbnb
        42161: 	'./img/chains/arbitrum.png', //arbitrum
        7777777: './img/chains/zora.png',// zora
        8453: 	'./img/chains/base.png',// base
        169: 	'./img/chains/manta.png',// manta
        7001: 	'./img/chains/zeta-testnet.png',// zeta testnet
        7000: 	'./img/chains/zeta.png',// zeta 
        167008: './img/chains/taiko-testnet.png', //taiko testnet 
        5000: './img/chains/mantle.png', // mantle
        1101: './img/chains/polygon-zkevm.png', // polygon zkevm 
        68840142: './img/chains/frame-testnet.png', // frame testnet
        324: './img/chains/zksync.png', // zksync
        10: './img/chains/optimism.png', // optimism
        195: './img/chains/x1-testnet.png', // x1 testnet
        80085: './img/chains/berachain-testnet.png', // berachain testnet
        1890: './img/chains/lightlink.png', // lightlink mainnet 
      }
    })


        return (
            <WagmiConfig config={wagmiConfig}>
                {/* <GetChain onEvent={(v) => {currentChainxxx2(v)}} /> */}
                {/* <RainbowKitProvider coolMode modalSize="compact" chains={chains}> */}
                    <Header />
                    <div style={{ width: '100%', height: '75px' }} ></div>
                    <Routes>
                        {/* <Route path="/"         element={<Home01 />} />
                        <Route path="/chain"    element={<MintPage RPCAction={(data) => { console.log(data) }} />} />
                        <Route path="/faqs"     element={<Faqs />} />
                        <Route path="/factory"  element={<Factory />} />
                        <Route path="*"         element={<Page404 />} /> */}

                        <Route path="/"         element={<Factory />} />
                        <Route path="/factory"  element={<Factory />} />
                        <Route path="*"         element={<Page404 />} />
                        {/* <Route path="/home"  element={<Home01 />} /> */}
                        <Route path="/chain"    element={<MintPage RPCAction={(data) => { console.log(data) }} />} />

                    </Routes>
                    {/* <Footer /> */}
                {/* </RainbowKitProvider> */}
            </WagmiConfig>
        )
}
  
export default App
  