import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useBalance, useContractWrite, useNetwork, useAccount } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { getNetwork } from '@wagmi/core'
import { getPublicClient } from '@wagmi/core'
import Image from 'react-bootstrap/Image'
import MintList from '../components/nftmintlist/mintlist'
import NFT from './NFT.json'
import {
  mintPage,
  categoryList,
  categoryNFTList,
} from '../assets/real-data/data-chains'

const { parseEther } = require('ethers')
window.Buffer = window.Buffer || require('buffer').Buffer

export const MintPage = ({ RPCAction }) => {
    const [categoryState, setCategoryState] = useState([])
    // const [NFTInfo, setNFTInfo] = useState([])
    const [chain, setChain] = useState({ Chain_name: '' })
    const [networkChain, setNetworkChain] = useState(null)
    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [mintAmount, setMintAmount] = useState(1)
    const [accountBalance, setAccountBalance] = useState()

    useEffect(() => {
        _init()
    }, [])

    // useEffect(() => {
    //     handleMintPublic()
    // }, [NFTInfo])

    const { address } = useAccount({
        onDisconnect() {
            return '0x0000000000000000000000000000000000000000'
        },
    })

    const balance = useBalance({
            address: address,
            formatUnits: 'ether',
            onSuccess(data) {
            setAccountBalance(Number(data.formatted))
        },
    })

    const _init = async () => {
        const urlSearchString = window.location.search
        const params = new URLSearchParams(urlSearchString)
        const v = params.get('id')

        if (v) {
            let res = await mintPage({ Chain: v })
            let resCategory = await categoryList({ Chain: v })
            if (res && resCategory) {
                setChain(res)
                setCategoryState(resCategory)
                watchNetwork()
            }
        }
        setIsLoadingPage(false)
    }

    const [networkId, setNetworkId] = useState(0);

    const { chain:getChainX } = getNetwork()

    useEffect(() => {
        if(address !== undefined) {
            setNetworkId(getNetwork().chain.id)
        }
        else {
            setNetworkId(0)
        }
    }, [getChainX])

    const watchNetwork = () => {
        const { chain } = getNetwork()
        if(chain) {
            setNetworkChain(chain)
            RPCAction(chain.id)
            
            setNetworkId(chain.id)
        }
    }

    const handleMintPublic = async (NFTInfo) => {
        try {
            if (accountBalance >= NFTInfo[0].Catgory_total_price) {
                for (let i in NFTInfo) {
                    PBWrite?.({
                        address: NFTInfo[i].NFT_address,
                        value: parseEther(NFTInfo[i].NFT_price.toString()),
                    })
                }
            } else {
                console.log('ETH amount is not sufficient')
            }
        } catch (err) {
            console.log('Error:', err)
        }
    }

    const { data: PBData, write: PBWrite } = useContractWrite({
        abi: NFT.abi,
        functionName: 'mint',
        args: [mintAmount],
        account: address,
        onSuccess(data) {
            // Handle success
        },
        onError(PBerror) {
            // Handle error
        },
    })

    return (
        <div>
        <section className="tf-dashboard tf-tab">
            {/* <div className="tf-container">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <div className='x-chain-icon' style={{ margin: '0' }}><Image src={`./img/chains/${chain.img}`} alt={chain.Chain_name} rounded width={200} height={200} /></div>
                    <h4 className="title-dashboard" style={{ marginLeft: '20px' }}>{chain.Chain_name}</h4>
                    <span className="badge bg-secondary">New</span>
                </div>
                <MintList data={categoryState} action={(v) => { setNFTInfo(v) }} />
            </div> */}
            <div className="tf-container" style={{width:'800px'}}>
                <div className="text-center">
                    <img src={`./img/chains/${chain.img}`} className="rounded" style={{width:'125px', borderRadius:'15px', height:'125px'}} alt={chain.Chain_name}/>
                    <h1 style={{marginTop:'10px', marginBottom:'30px', fontSize:'50px'}}>{chain.Chain_name}</h1>
                </div>
                <MintList PBData={PBData} networkChainId={networkId}  data={categoryState} address={address} thisPageChain={chain} action={(v) => { handleMintPublic(v) }} />
            </div>

        </section>
        </div>
    )
}
