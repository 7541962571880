import React , {useState, useEffect, useRef, lazy, Suspense } from 'react';
import { ethers, parseEther } from 'ethers';
import DataABI from '../assets/real-data/ABI.json';
import DataByte from '../assets/real-data/ByteCode.json';
import { useEthersSigner } from '../utils/ethers.ts';
import { useBalance, useContractWrite, useNetwork, useAccount } from 'wagmi'
// import PopFacComponant from '../components/popComponent/popComponent';
import { useSwitchNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'

import { setDeployPopContract, pop_current_chain } from '../assets/real-data/popfactory.js'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// const PopFacComponant = lazy(() => delayLazyload(import('../components/popComponent/popComponent')))
const PopFacComponant = lazy(() => import('../components/popComponent/popComponent'))

// function delayLazyload(promise) {
//     return new Promise((resolve, reject) => {
//         setTimeout(resolve, 1000)
//     }).then(() => promise)
// }

Factory.propTypes = {
    
};

function Factory(props) {

    const { address } = useAccount({
        onDisconnect() {
            return '0x0000000000000000000000000000000000000000'
        },
    })

    const { open } = useWeb3Modal()

    const connectW = async () => {
        open({ view: 'Connect' })
    }
    
    const switchNetworkModal = async () => {
        open({ view: 'Networks' })
    }

    const balance = useBalance({
            address: address,
            formatUnits: 'ether',
            onSuccess(data) {
            // setAccountBalance(Number(data.formatted))
        },
    })

    const accountBalance = async () => {
        return balance.data.formatted
    }

    const [isDeploy, setIsDeploy] = useState(false)
    const [pinValues, setPinValues] = useState(['']);
    // const [accountBalance, setAccountBalance] = useState()
    const { chain } = useNetwork()
    // console.log(chain)
    const [status, setStatus] = useState('');
    const signer = useEthersSigner()
    const { isSuccess, switchNetwork } = useSwitchNetwork()
    const input1 = useRef({ value: '' });
    const input2 = useRef({ value: '' });
    const input3 = useRef({ value: '' });

    const validateInputs = async () => {

        const refArray = [input1,input2,input3]
        // console.log("refArray : ", refArray)
        // วนลูปตรวจสอบทุกรายการ ref
        for (const ref of refArray) {
            const value = ref.current.value;
    
            // ตรวจสอบการกรอกอักขระพิเศษ
            const specialCharacters = ['"', "'", '<', '>', '&', ';', '`', '|', '\\', '/'];
            for (const character of specialCharacters) {
                if (value.includes(character)) {
                    // พบอักขระพิเศษ
                    alert(`You cannot input special characters. "${specialCharacters}"`);
                    console.log(`You cannot input special characters. "${specialCharacters}"`);
                    return false;
                }
            }
            console.log(`Input for ref ${ref.current.value} is valid (True)`);
        }
        // ถ้าทุกรายการ ref ถูกต้อง
        console.log("All True")
        handleDeployPOP()
        return true;
    }

   
    const handleDeployPOP = async () => {

        let pop_info = await pop_current_chain({ Chain_id: chain?.id })
        // console.log("info :", pop_info)
        // console.log("info :", pop_info.Abi_Contract)
        // console.log("info :", pop_info.Bytecodes_content)
        try {
            let Abi_Contract = JSON.parse(pop_info.Abi_Contract)
            // console.log(Abi_Contract)
            let json = {}
            const text_4 = input1.current.value +','+input2.current.value+','+input3.current.value

            setIsDeploy(true)
            const factory = new ethers.ContractFactory(Abi_Contract, pop_info.Bytecodes_content, signer);
            const deploy = await factory.deploy()
            const txReceipt = await deploy.deploymentTransaction().wait();

            json['Contract_address'] = txReceipt?.contractAddress
            json['UID'] = txReceipt?.contractAddress.substring(txReceipt?.contractAddress.length-5, txReceipt?.contractAddress.length)
            json['Chain_id'] = chain?.id
            json['Deployer_address'] = txReceipt?.from
            json['Block_number'] = txReceipt?.blockNumber
            json['Pop_config_id'] = pop_info?.Config_id
            json['Censor_text'] = 0
            json['Pop_text'] = text_4

            //send to db
            // console.log(json)
            setDeployPopContract(json)

            // console.log(txReceipt)
            console.log(`POP Contract deployed, address: ${txReceipt.contractAddress}`);
            const contractAddCreated = `Deployed, address: ${txReceipt.contractAddress}`
            setStatus(contractAddCreated)
        } catch (error) {
            console.error("Error1 :",error);
            setStatus('Deployment failed or insufficient funds');
        } finally {
            setIsDeploy(false)
        }
    };


    const { data: dataPop, write: clickPop } = useContractWrite({
        functionName: 'pop',
        account: address,
        onSuccess(data) {
            // Handle success
        },
        onError(PBerror) {
            // Handle error
        },
    })

    const interactPop = async (pop_factory) => {
            // console.log(pop_factory)
            // console.log(pop_factory.Pop_gas)
            // console.log(balance.data.formatted)
        try {
            if (balance.data.formatted >= pop_factory.Pop_gas) {
                let abi = JSON.parse(pop_factory.Abi_Contract)
                clickPop?.({
                    address: pop_factory.Contract_address,
                    value: parseEther(String(pop_factory.Pop_gas)),
                    abi: abi,
                })
            } else {
                console.log('ETH amount is not sufficient')
            }
        } catch (err) {
            console.log('Error:', err)
        }
    }

    async function switchChain(pop_factory) {
        switchNetwork?.(pop_factory.Chain_id)
    }

    const handlePop = async (pop_factory) => {

        if(chain.id === pop_factory.Chain_id) {
            console.log("same chain")
            interactPop(pop_factory)
        } else {
            console.log("not same chain")
            switchChain(pop_factory)

        }
    }

    const pinContainerRef = useRef(null);
    
    const handleKeyUp = (index, event) => {
        const target = event.target;

        const maxLength = parseInt(target.getAttribute("maxlength"), 10);
        // console.log(maxLength)
        const myLength = target.value.length;

        if (myLength >= maxLength) {
            let next = target;
            while ((next = next.nextElementSibling) !== null) {
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }

        if (myLength === 0) {
            let next = target;
            while ((next = next.previousElementSibling) !== null) {
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          <a style={{fontSize:'14px'}}>The only rule is that users can only type messages of 12 characters.</a>
        </Tooltip>
    );

    return (
        <div>
            <section className="tf-faq">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-sm-8 col-md-7 col-lg-6">
                            <div className="tf-card m-4" style={{border:'1px solid #FFF'}}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h2 className="heading" style={{color:'#FFF'}}>Message Pager</h2>
                                            <div style={{textAlign:'center'}}>
                                                <button
                                                    type="button"
                                                    className="btn btn-lg col-sm-12 col-md-10 col-lg-8"
                                                    onClick={switchNetworkModal}
                                                    style={{backgroundColor:'#1D1C1C',margin:'20px auto 20px auto', display:'block', borderRadius:'50px', padding: '13px 40px', fontSize: '18px'}}
                                                >
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                    <b style={{marginLeft:'12px'}}>{chain ? (<>{chain.name}</>) : (<>Select chain</>)}</b>
                                                </button>
                                            
                                                <div className='col-sm-12 col-md-10 col-lg-8' style={{margin:'0 auto', padding:'0px'}}>
                                                    <div key={'trigger_render'} style={{textAlign:'end'}}>
                                                        <OverlayTrigger
                                                                placement="left"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltip}
                                                            >
                                                            <a style={{fontSize:'12px'}}><i className="fa-regular fa-circle-question"></i></a>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="pin-code" ref={pinContainerRef}>
                                                        {
                                                            pinValues.map((value, index) => {
                                                                if(index === 0) {
                                                                    return <input type="text" className="ml-0 mr-0 p-4" maxLength="24" placeholder='type your message.' autoFocus
                                                                    onKeyUp={(event) => {handleKeyUp(index, event)}}
                                                                    ref={input1}
                                                                    />
                                                                }
                                                                if(index === 1) {
                                                                    return <input type="text" className="ml-3 mr-3 p-4" maxLength="4" placeholder='your'
                                                                    onKeyUp={(event) => {handleKeyUp(index, event)}}
                                                                    ref={input2}
                                                                    />
                                                                   
                                                                } else {
                                                                    return <input type="text" className="ml-0 mr-0 p-4" maxLength="4" placeholder='msg'
                                                                    onKeyUp={(event) => {handleKeyUp(index, event)}}
                                                                    ref={input3}
                                                                    />  
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>


                                                { chain ? (<>
                                                <button
                                                    type="button"
                                                    className="btn btn-lg col-sm-12 col-md-10 col-lg-8"
                                                    onClick={validateInputs}
                                                    // block
                                                    disabled={isDeploy}
                                                    style={{backgroundColor:'#00BFFF', margin:'20px auto 20px auto', display:'block', borderRadius:'50px', padding: '13px 40px', fontSize: '18px'}}
                                                >
                                                    { !isDeploy ? <b>Post</b> : <b>Posting...</b> }
                                                </button>
                                                </>) : (<>
                                                <button
                                                    type="button"
                                                    className="btn btn-lg col-sm-12 col-md-10 col-lg-8"
                                                    onClick={connectW}
                                                    // block
                                                    disabled={isDeploy}
                                                    style={{backgroundColor:'#00BFFF', margin:'20px auto 20px auto', display:'block', borderRadius:'50px', padding: '13px 40px', fontSize: '18px'}}
                                                >
                                                    <b>Connect Wallet</b>
                                                </button>
                                                </>)}

                                                <p style={{margin:'auto', fontSize:'13px',wordBreak:'break-all'}}>
                                                    {status ? (<>{status}</>):(<></>)}
                                                </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Suspense fallback={'Loading...'}>
                <PopFacComponant
                    data={{ chain: chain }}
                    onEvent={(v) => {handlePop(v)}}
                />
            </Suspense>
        </div>
    );
}

export default Factory;