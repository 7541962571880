import './polyfills.ts';
import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
      <React.Fragment>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </React.Fragment>
  );

