import React from 'react';


function Page404(props) {
    return (
        <div className="page-404">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ marginTop: '150px', marginBottom: '150px' }}>
                            <h1>404</h1>
                            <h2>Page Not Found</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;