import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

const api = axios.create({ baseURL, headers })

//array fetch
async function request(endpoint, data) {
  try {
    const response = await api.post(endpoint, data)
    if(response.data.action === 'SUCCESS') {
      return response.data.res
    } else {
      return false
    }
  } catch (error) {
    console.error(`An error occurred at ${endpoint}:`, error)
    throw error
  }
}

//one data fetch
async function request_one(endpoint, data) {
  try {
    const response = await api.post(endpoint, data)
    if(response.data.action === 'SUCCESS') {
      return response.data.res[0]
    } else {
      return false
    }
  } catch (error) {
    console.error(`An error occurred at ${endpoint}:`, error)
    throw error
  }
}

export async function all() {
    return request('/PopFactory/all')
}

export async function setDeployPopContract(data) {
  return request_one('PopFactory/create-popfactory', data)
}

export async function pop_current_chain(data){
  return request_one('PopConfig/pop_current_chain', data)
}