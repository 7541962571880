import { Chain } from 'wagmi'

export const zeta = {
  id: 7000,
  name: 'Zeta',
  network: 'zeta',
  nativeCurrency: {
    decimals: 18,
    name: 'ZETA',
    symbol: 'ZETA',
  },
  rpcUrls: {
    public: { http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'] },
    default: { http: ['https://zetachain-mainnet-archive.allthatnode.com:8545'] },
  },
  blockExplorers: {
    etherscan: { name: 'explorerzetachain', url: 'https://explorer.mainnet.zetachain.com' },
    default: { name: 'explorerzetachain', url: 'https://explorer.mainnet.zetachain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 1111,
    },
  },
} as const satisfies Chain
