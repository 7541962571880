import { Chain } from 'wagmi'

export const taiko = {
  id: 167008,
  name: 'Taiko Katla L2',
  network: 'taiko',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://taiko-katla.blockpi.network/v1/rpc/public'] },
    default: { http: ['https://rpc.katla.taiko.xyz'] },
  },
  blockExplorers: {
    etherscan: { name: 'explorerjolnirtaiko', url: 'https://explorer.katla.taiko.xyz/' },
    default: { name: 'explorerjolnirtaiko', url: 'https://explorer.katla.taiko.xyz/' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 1111,
    },
  },
} as const satisfies Chain
