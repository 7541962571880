import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import '../../assets/air3style.css';
import { categoryNFTList } from '../../assets/real-data/data-chains';

import { useSwitchNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'

const Mintlist = ({ data, action, address ,thisPageChain, networkChainId, PBData }) => {
  const getCategoryNFT = async (id) => {
    const res = await categoryNFTList({ Catgory_id: id });
    action(res);
  };

  const { switchNetwork } = useSwitchNetwork()

  const { open:connectBTN } = useWeb3Modal()

  return (
    <div className="x-table justify-content-center">
      <div className="row mb-3 text-center x-content-table">
        <div className="col-6 x-col-left x-head-table-text">Collection</div>
        <div className="col-6 x-col-right x-head-table-text">Mint</div>
      </div>
      {data.map((idx) => (
        <>
        <div key={idx.Catgory_id} className="row mb-3 text-center x-content-table d-flex justify-content-center">
          <div className="col-6 x-col-left">
            <div className="x-collection-img text-left">
                <img src={`./img/chains/not-found.jpg`}
                    alt="user" />
                <div className="x-collection-info align-self-center">
                    <p className="x-collection-name">{idx.Catgory_name}</p>
                    <p className="x-collection-minted">{idx.Catgory_total_price} {idx.Catgory_total_price_unit}</p>
                    <p className="x-collection-minted">3423/∞</p>
                </div>
            </div>
          </div>

          <div className="col-6 x-col-right">
            
            {
              address === undefined ? 
              <>
                <button type="button" className="btn btn-warning btn-lg x-mintButton" 
                onClick={() => {
                  connectBTN()
                  }}>
                Connect
                </button>
              </>
              : networkChainId != thisPageChain.Chain_id ?
              <>
                <button type="button" className="btn btn-warning btn-lg x-mintButton" 
                onClick={() => {
                  switchNetwork?.(thisPageChain.Chain_id)
                } }>
                Switch Chain
                </button>
              </>
              :
              <>
                <button type="button" className="btn btn-warning btn-lg x-mintButton" 
                onClick={() => getCategoryNFT(idx.Catgory_id)}>
                  Mint
                </button>
              </>
           }


          </div>
        </div>
        </>
      ))}
    </div>
  );
};

Mintlist.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Mintlist;