import React , { useState , useEffect } from 'react';
import { useNetwork } from 'wagmi'


import { goerli, base, zkSync, polygon, arbitrum, mainnet, linea, polygonZkEvm } from 'wagmi/chains'
// import { scroll } from './customChain/scroll.ts'
// import { opBNB } from './customChain/opBNB.ts'
// import { Lightlink } from './customChain/lightlink.ts'
// import { tenet } from './customChain/tenet.ts'


const GetChain = ({onEvent}) => {

    const { chain } = useNetwork()

    const currentChain = async () => {
        
        console.log("RUN currentChain Get Chian")
        console.log("Chain Name1 : ", chain.name)
        // let xxx = 'run'
        // onEvent(xxx)
        if (chain) {
            // let current = chain?.rpcUrls.public
            // console.log("current :::", current)
            console.log("Chain Name2 : ", chain.name)
            onEvent(chain?.rpcUrls.public)
        } else {
            // let current = goerli?.rpcUrls.public
            // console.log("current :::", current)
            console.log("Chain Name3 : ", chain.name)
            onEvent(goerli?.rpcUrls.public)
        }
    }

    useEffect(() => {
        currentChain()
    }, [chain])

    useEffect(() => {
        currentChain()
    }, [])

     return <> aaaa </>
}

export default GetChain;