import { Chain } from 'wagmi'

export const x1testnet = {
  id: 195,
  name: 'X1 Testnet',
  network: 'X1',
  nativeCurrency: {
    decimals: 18,
    name: 'OKB',
    symbol: 'OKB',
  },
  rpcUrls: {
    public: { http: ['https://testrpc.x1.tech'] },
    default: { http: ['https://testrpc.x1.tech'] },
  },
  blockExplorers: {
    etherscan: { name: 'oklinkx1testenet', url: 'https://www.oklink.com/x1-test/' },
    default: { name: 'oklinkx1testenet', url: 'https://www.oklink.com/x1-test/' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 1111,
    },
  },
} as const satisfies Chain
