const menus = [
    // {
    //     id: 1,
    //     name: 'Nitro',
    //     links: '/#',
    // },
    {
        id: 2,
        name: 'Pager',
        links: '/#',
    },
    // {
    //     id: 3,
    //     name: 'home-test',
    //     links: '/home',
    // },
    {
        id: 4,
        name: 'Docs',
        links: 'https://air3.gitbook.io/air3',
    },
    // {
    //     id: 4,
    //     name: 'FAQs',
    //     links: '/#',
    // },
]

export default menus;