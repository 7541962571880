import { Chain } from 'wagmi'

export const frame = {
  id: 68840142,
  name: 'Frame Testnet',
  network: 'frame',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.testnet.frame.xyz/http'] },
    default: { http: ['https://rpc.testnet.frame.xyz/http	'] },
  },
  blockExplorers: {
    etherscan: { name: 'explorertestnetframe', url: 'https://explorer.testnet.frame.xyz' },
    default: { name: 'explorertestnetframe', url: 'https://explorer.testnet.frame.xyz' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 1111,
    },
  },
} as const satisfies Chain
