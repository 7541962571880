import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
// const baseURL = 'http://lerdlumyong.xyz:3033'


console.log('Base :', baseURL)
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

const api = axios.create({ baseURL, headers })

//array fetch
async function request(endpoint, data) {
  try {
    const response = await api.post(endpoint, data)
    if(response.data.action === 'SUCCESS') {
      return response.data.res
    } else {
      return false
    }
  } catch (error) {
    console.error(`An error occurred at ${endpoint}:`, error)
    throw error
  }
}

//one data fetch
async function request_one(endpoint, data) {
  try {
    const response = await api.post(endpoint, data)
    if(response.data.action === 'SUCCESS') {
      return response.data.res[0]
    } else {
      return false
    }
  } catch (error) {
    console.error(`An error occurred at ${endpoint}:`, error)
    throw error
  }
}

export async function dataChain() {
  return request('/Chain/All-ChainCollections')
}

export async function mintPage(data) {
  return request_one('/Chain/find',data)
}

export async function categoryList(data) {
  return request('/Chain/get-ChainWithNFTCatgory',data)
}

export async function categoryNFTList(data) {
  return request('/NFTCatgory/getNFTCategoryWithNFTInfo',data)
}