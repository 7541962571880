import { Chain } from 'wagmi'

export const scroll = {
  id: 534352,
  name: 'Scroll',
  network: 'scroll',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.scroll.io'] },
    default: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ScrollScan', url: 'https://scrollscan.com' },
    default: { name: 'ScrollScan', url: 'https://scrollscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 159205,
    },
  },
} as const satisfies Chain
